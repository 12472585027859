$cblue: #696ff1;
$cwhite: #ffffff;
$cblack: #242424;

$fbig: 64px;
$f24: 24px;
$f20: 20px;
$f16: 16px;

$m1470: 1370px;
$m1470p: 0 15px;

$mobile-max-width: 1150px;

$scroll-margin-desktop: 100px;
$scroll-margin-mobile: 60px;
