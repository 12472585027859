.CardAuth {
  border-radius: 15px;
  background-color: var(--color-card-auth);
  box-shadow: 0px 0px 20px 20px var(--color-card-auth);

  &.CardAuth__space {
    margin: 30px;
    padding: 10px;
  }
}
