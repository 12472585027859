@import '../../../Styles/tokens';

.section {
  max-width: $m1470;
  padding: $m1470p;
  margin: 120px auto;
  scroll-margin-top: $scroll-margin-desktop;
  @media screen and (max-width: $mobile-max-width) {
    margin: 50px auto;
    scroll-margin-top: $scroll-margin-mobile;
  }
}

.section__title {
  color: $cblue;
  font-family: 'Montserrat';
  font-size: $fbig;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 50px;
  margin-left: 50px;
  opacity: 0;
  transition: 0.8s;
  transform: translateX(-100px);
  @media screen and (max-width: $mobile-max-width) {
    text-align: center;
    margin-left: 0;
    font-size: 40px;
  }
  @media screen and (max-width: 320px) {
    font-size: 36px;
  }
}

.section__row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  @media screen and (max-width: $mobile-max-width) {
    display: block;
  }

  .section__right {
    display: flex;
    flex-direction: column;
    gap: 54px;
    width: 710px;
    @media screen and (max-width: $mobile-max-width) {
      width: 100%;
      gap: 36px;
      transform: translateX(0px);
      opacity: 1;
    }
    .item {
      position: relative;
      width: 100%;
      padding: 36px 20px 36px 20px;
      border-radius: 16px;
      background: rgba(105, 111, 241, 0.07);
      display: flex;
      gap: 35px;
      align-items: flex-start;
      cursor: pointer;
      transition: 0.3s;
      .icon {
        display: none;
      }
      span {
        font-weight: 700;
      }
      .index {
        font-family: 'Montserrat';
        font-size: 88px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%;
        color: $cblue !important;
      }
      .item__title {
        font-family: 'Montserrat';
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 26px;
        color: $cblue !important;
      }
      .item__body {
        font-family: 'Open Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: #434343 !important;
      }
      @media screen and (max-width: $mobile-max-width) {
        padding: 36px 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
        background: $cblue !important;
        text-align: center;
        opacity: 0;
        transition: 1.4s;
        transform: translateX(100px);
        .icon {
          display: block;
          margin: auto;
          margin-bottom: 10px;
        }
        .index {
          position: absolute;
          color: $cwhite !important;
          font-size: 36px;
          top: 15px;
          left: 15px;
          margin-bottom: 0px;
        }
        .item__title {
          font-size: 24px;
          color: $cwhite !important;
          margin-bottom: 16px;
        }
        .item__body {
          font-size: 16px;
          color: $cwhite !important;
        }
      }
    }
    .active {
      background: $cblue;
      color: $cwhite !important;
      .item__title {
        color: $cwhite !important;
      }
      .index {
        color: $cwhite !important;
      }
      .item__body {
        color: $cwhite !important;
      }
    }
  }

  .section__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-left: 50px;
    img {
      width: 425px;
      height: 860px;
      object-fit: cover;
    }

    @media screen and (max-width: $mobile-max-width) {
      display: none;
    }

    .nav {
      display: flex;
      gap: 24px;
      .navItem {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: $cblue;
        opacity: 0.2;
        transition: 0.2s;
        &.activeNav {
          opacity: 1;
        }
      }
    }
  }
}
