@import '../../../Styles/tokens';

.section {
  background: $theme-first-background;
  padding: 90px 0px 90px 0px;
  scroll-margin-top: $scroll-margin-desktop;
  @media screen and (max-width: $mobile-max-width) {
    padding: 50px 0;
    scroll-margin-top: $scroll-margin-mobile;
  }
  .container {
    margin: auto;
    max-width: $m1470;
    padding: $m1470p;
  }
  .section__title {
    font-family: 'Montserrat';
    font-size: 64px;
    color: $cblue;
    font-weight: 700;
    margin-bottom: 70px;
    opacity: 0;
    transition: 0.8s;
    transform: translateX(-100px);
    @media screen and (max-width: $mobile-max-width) {
      font-size: 40px;
      margin-bottom: 50px;
    }
    @media screen and (max-width: 320px) {
      font-size: 36px;
    }
  }
  .FAQ {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 36px;
    margin-bottom: 80px;
    @media screen and (max-width: $mobile-max-width) {
      margin-bottom: 50px;
    }
    .FAQ__item {
      border-top: $theme-FAQ-border;
      width: 100%;
      padding-top: 33px;
      padding-right: 30px;
      opacity: 0;
      transition: 1.3s;
      transform: translateY(100px);
      cursor: pointer;
      .FAQ__row {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;
        .question {
          font-family: 'Montserrat';
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          color: $theme-first-background-opposite;
          @media screen and (max-width: $mobile-max-width) {
            font-size: 20px;
            max-width: 80%;
          }
        }
        .FAQ__arrow {
          transition: 0.2s;
          @media screen and (max-width: $mobile-max-width) {
            min-width: 27px;
            height: 15px;
          }
          &.arrow_active {
            rotate: 180deg;
          }
        }
      }
      .answer {
        white-space: pre-wrap;
        font-size: 24px;
        font-family: 'Montserrat';
        color: $theme-FAQ-answer;
        margin-top: 30px;
        max-width: 700px;
        font-weight: 400;
        @media screen and (max-width: $mobile-max-width) {
          font-size: 14px;
        }
      }
    }
  }
}
