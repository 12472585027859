.Play__button {
  position: absolute;
  z-index: var(--z-index-A);
  height: 80px;

  ion-icon {
    color: var(--color-main);
    --ionicon-stroke-width: 24px;
    font-size: 80px;
    background: rgba(255, 255, 255, 0.2);
  }
}

.View__container {
  width: 100%;
  height: 100%;

  position: relative;

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    > div {
      > video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border: none;
        outline: none;
      }
    }
  }
}

.IonIcon_Placeholder {
  --ionicon-stroke-width: 24px;
  font-size: 21px;
  color: var(--color-main-medium);

  width: 50%;
  height: 50%;
}
