.input-disabled {
  opacity: 1 !important;
}

.select-disabled {
  opacity: 1 !important;
}

ion-input {
  * .label-text-wrapper {
    margin-right: 0 !important;
  }

  .native-input {
    bottom: 0 !important;
  }

  .input-wrapper {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
}
