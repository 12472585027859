.Image__container {
  position: relative;
  margin-bottom: 10px;
}

.Text_Container {
  width: 100%;
  overflow: hidden;
}

.Text_Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-main);
}

.Text_Subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
}
