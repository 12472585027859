.IonIcon {
  color: var(--color-main-high);
  --ionicon-stroke-width: 12px;
  font-size: 32px;

  &.IonIcon__disabled {
    color: var(--color-main-low);
  }

  &.IonIcon__left {
    margin-right: 5px;
  }

  &.IonIcon__right {
    margin-left: 5px;
  }
}

.NavigationButton {
  height: 100%;
  width: 100%;
  padding: 5px;
}
