@import '../../../Styles/tokens';

.section {
  display: flex;
  justify-content: space-between;
  max-width: $m1470;
  padding: $m1470p;
  margin: 120px auto;
  gap: 20px;
  align-items: center;
  button {
    margin: auto;
  }
  @media screen and (max-width: $mobile-max-width) {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin: 50px 0;
  }
  h2 {
    font-size: 44px;
    color: #434343;
    font-family: 'Montserrat';
    line-height: 120%;
    font-weight: 400;
    transform: translateY(-100px);
    opacity: 0;
    transition: 1.5s ease;
    @media screen and (max-width: $mobile-max-width) {
      text-align: center;
      font-size: 30px;
      margin-bottom: 25px;
    }
    @media screen and (max-width: 320px) {
      font-size: 26px;
    }
    span {
      font-weight: 700;
      color: $cblue;
    }
  }
}

.section__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  max-width: 740px;
  @media screen and (max-width: 1300px) {
    width: 60vw;
  }
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 400;
    padding: 0;
    transform: translateX(-100px);
    opacity: 0;
    transition: 1.5s ease;
    .activeItem {
      color: $cblue !important;
      font-weight: 700; // TODO: Test
      span {
        width: 100%;
        height: 1px;
        background: $cblue;
        display: block;
        position: absolute;
        bottom: -4px;
      }
    }
    li {
      cursor: pointer;
      position: relative;
      transition: 0.2s;
      color: $cblack !important;
    }
  }
}
.slider {
  display: flex;
  gap: 16px;
  max-width: 100vw;
  overflow-x: auto;
  margin-bottom: 25px;
  padding: 0 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .slideTitle {
      color: $cblue;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
}

.animation {
  opacity: 0;
  transform: translateX(-100px);
  transition: 1.3s;
  @media screen and (max-width: $mobile-max-width) {
    opacity: 0;
    transform: translateY(100px);
    transition: 1.3s;
  }
}

.slideImageContainer {
  background-size: cover;
  border-radius: 8px;
  z-index: -2;

  .slideOverlappedText {
    color: black;
    white-space: pre-wrap;
    stroke-width: 2;
    text-align: center;
    font-family: 'SuezOne', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 26px;
    line-height: 120%;
    position: relative;

    &:after {
      content: attr(data-text);
      -webkit-text-stroke: 4px white;
      color: white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}

.list_goods {
  text-align: center;
  margin-top: 10px;
  padding: 0;

  li {
    color: $cblack;
    list-style: none;

    p {
      color: $cblack;
      font-family: 'Open Sans';
      font-weight: 450;
      font-size: 17px;
    }
  }
}

.listItemText {
  font-size: 18px !important;
  color: #434343;
  font-family: 'Open Sans' !important;
  line-height: 140%;
  font-weight: 600;
  // color: red !important;
  // border: solid 1px red;
}
