@import "../../../styles/tokens";

.ButtonWithUnderline {
  height: 40px;

  p {
    font-family: $font-family-secondary;
    letter-spacing: 0px;
    font-size: 18px;
  }

  hr {
    height: 1px;
    width: 100%;
    background-color: var(--color-main);

    &.hidden {
      opacity: 0%;
    }
  }
}
