@import './tokens';
@import './fonts';
@import './ionic';

html {
  color: var(--color-main);
  font-family: $font-family-main, $font-family-default;
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  /* Disable swipe navigation on desktop for Chrome */
  /* Safari navigation is blocked in in <SafariSwipeBlocker /> */
  overscroll-behavior-x: contain;
}

a {
  text-decoration: none;
  color: inherit;
}

h2,
h3,
h4,
h5,
h6,
p {
  font-family: $font-family-main, $font-family-default;
  margin: 0;
  padding: 0;
  padding-left: 0 !important;
  word-break: break-word;
  color: var(--color-main);
}

li {
  color: var(--color-main);
}

h1 {
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
}

h2 {
  font-size: $font-size-regular;
  font-weight: $font-weight-bold;
}

h3 {
  font-size: $font-size-regular;
  font-weight: $font-weight-bold;
}

h4 {
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  opacity: $transparency-middle;
}

p {
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
}

/* Autocomplete background fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--color-card-auth) inset !important;
  -webkit-text-fill-color: white !important;
  border-radius: 10px;
}

/* Remove arrows for <input type="number"> */
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Prevent zoom gesture */
* {
  touch-action: manipulation;
}

/* Custom scrollbar */
.ScrollbarsCustom-TrackY {
  height: 100% !important;
  top: 0px !important;
  width: 4px !important;
  margin-right: 3px !important;
}

.ScrollbarsCustom-ThumbY {
  background: grey !important;
}

.ScrollbarsCustom-Content {
  display: block !important;
}

.ScrollbarsCustom {
  max-height: calc(100vh - 125px) !important;
}

/* Swiper */
.swiper {
  padding-bottom: 15px !important;

  .swiper-pagination {
    --bullet-background: white;
    --bullet-background-active: white;
    bottom: 0px !important;
  }
}
