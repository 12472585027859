@import '../../styles/tokens';

// TODO SEO: use this instead h4
.Input__UnderLabel {
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
  opacity: $transparency-middle;
}

.Modal__CloseButton {
  color: var(--color-main-high);
  width: 48px;
  height: 48px;
  margin-left: -10px;
}

.Text__Content {
  text-align: center;
  white-space: pre-wrap;
}
