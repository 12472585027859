.Container {
  padding: 5px;
  margin: 5px;
  margin-right: 0px;
}

.IonIcon_Arrow {
  --ionicon-stroke-width: 24px;
  color: var(--color-main-high);
  font-size: 24px;

  max-width: 25px;
  min-width: 25px;

  &.IonIcon_Arrow__invisible {
    color: rgba(0, 0, 0, 0);
  }
}

.Text__container {
  margin-left: 10px;
  margin-right: 5px;
}

.Text__description {
  color: var(--color-main-medium);
}
