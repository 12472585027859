.Checkbox__text {
  font-size: 15px;
  white-space: normal;
  word-break: normal;
}

.Checkbox__additional_info {
  font-size: 13px;
  color: var(--color-main-medium);
}
