.BusinessCard__сontainer {
  align-items: flex-start;
  padding: 10px 0;
}

.Button {
  width: 180px;
}

.IonSegment {
  --background: var(--color-search-block);
}

.SegmentButton {
  --indicator-color: rgb(130, 130, 130);
  text-transform: none;
  color: var(--color-main);
}

.BusinessCategory {
  color: var(--color-main-medium);
}

.Buttons__container {
  flex-wrap: wrap;
}

.Content__container {
  height: 100%;
  width: 100%;
}
