@import '../../styles/tokens';

$common-opacity: 0.5;

.IonBackButton {
  --icon-font-size: 24px;
  font-size: 16px;
  opacity: $common-opacity;
}

.IonTitle__h1 {
  color: var(--color-main);
  word-wrap: normal;
  white-space: break-spaces;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: $common-opacity;
}

.IonIcon__message {
  font-size: 32px;
}

.IonIcon__add {
  font-size: 40px;
}

.IonButton__help {
  --padding-start: 10px !important;
  --padding-end: 10px !important;

  --ion-color-base: #0ec254 !important;

  &.IonButton__help__desktop {
    padding-right: 30px;
  }
}
