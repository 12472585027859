@import '../../../Styles/tokens';

.header {
  z-index: 100;
  position: sticky;
  top: 0px;
  height: 100px;
  margin-top: -100px;
  background: $theme-first-background;
  padding-top: 25px;
  transition: 0.5s;
}
.logo {
  width: 290px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  max-width: $m1470;
  padding: $m1470p;
  margin: auto;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 1200px) {
    padding: 0px;
  }

  ul {
    list-style: none;
    display: flex;
    gap: 30px;
    font-family: 'Open Sans';
    font-size: $f16;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    p {
      color: $theme-first-background-opposite;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .button {
    padding-left: 0;
    color: $theme-first-background;
    display: flex;
    padding: 16px 36px 16px 36px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Open Sans';
    border-radius: 100px;
    transition: 0.2s;
    background: $theme-first-background-opposite;
    // background: pink;
    // color: pink;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.8;
    }
  }
}
