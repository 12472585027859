.CardAuth {
  position: relative;
}

.ButtonMicroWrapper {
  --background: var(--ion-color-secondary);
  --border-radius: 100%;
  --border-color: var(--ion-color-secondary);
  --border-width: 1px;
  --border-style: solid;

  position: absolute;
  top: -20px;
  right: -20px;
}

.Button__exit {
  font-size: 20px;
  margin: 0 10px;
}
