.Category__container {
  max-width: calc(100% - 30px);
  margin-bottom: 5px;
  width: fit-content;

  &.Category__container_opened {
    margin-bottom: -5px;
  }
}

.Subcategory__container {
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 10px;

  &.Subcategory__container__colored {
    background: var(--color-card-auth);
  }
}
