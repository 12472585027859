@import '../../../Styles/tokens';

.nav {
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 10px;
  background: $theme-first-background;
  height: 60px;
  margin-top: -60px;
  transition: 0.5s;
}

.navMenu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.fixed {
  z-index: 100;
  position: sticky;
  top: 0px;
}

.logo {
  img {
    width: 120px;
    height: 15px;
    position: relative;
    bottom: 2px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  top: 3px;
}

.button {
  color: $theme-first-background;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 600;
  padding: 11px 20px;
  border-radius: 100px;
  border: 1px solid $theme-first-background-opposite;
  transition: 0.2s;
  background: $theme-first-background-opposite;
  display: flex;
  gap: 5px;
  &:hover {
    opacity: 0.8;
  }
}

.menuActive {
  transform: translateX(0%) !important;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: $cblue;
  padding: 10px 10px 35px 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  color: white;
  transition: transform 0.2s;
  transform: translateX(100%);

  .nav {
    margin: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    height: auto;
    top: 0;
  }
  ul {
    padding: 0;
    list-style: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    height: 40%;
    justify-content: space-between;
    color: #fafafa;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .links {
    display: flex;
    gap: 50px;
    margin-bottom: 20px;
  }
  .download {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    img {
      height: 36px;
    }
  }
}

.ButtonMicroWrapper {
  --padding-top: 15px;
  --padding-bottom: 15px;
  --padding-start: 15px;
  --padding-right: 15px;
  margin: -15px;
}
