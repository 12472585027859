/* Stacks */
.Stack_Horizontal {
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.Justify_Center {
    justify-content: center;
  }

  &.Justify_End {
    justify-content: end;
  }

  &.Align_Items_Up {
    align-items: normal;
  }

  &.Gap_5 {
    gap: 5px;
  }

  &.Gap_10 {
    gap: 10px;
  }

  &.Gap_15 {
    gap: 15px;
  }

  &.Gap_20 {
    gap: 20px;
  }

  &.Gap_30 {
    gap: 30px;
  }
}

.Stack_Vertical {
  height: auto;
  display: flex;
  flex-direction: column;

  &.Gap_5 {
    row-gap: 5px;
  }

  &.Gap_10 {
    row-gap: 10px;
  }

  &.Gap_15 {
    row-gap: 15px;
  }

  &.Gap_20 {
    row-gap: 20px;
  }

  &.Gap_30 {
    row-gap: 30px;
  }
}

/* Common addons */
.Flex_On {
  flex: 1;
}

.Width_100 {
  width: 100%;
}

.Height_100 {
  height: 100%;
}

.Hover_Animation_Transparency {
  &:hover {
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -webkit-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
  }
}

/* List of react-window */
.DisableScrollbar {
  overflow-x: scroll !important;
  overflow-y: hidden !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}
