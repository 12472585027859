.textarea-disabled {
  opacity: 1 !important;
}

ion-textarea {
  * .label-text-wrapper {
    margin-right: 0 !important;
  }

  .textarea-wrapper {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
}
