.Tag_Title {
  font-size: 14px;
  line-height: 1;
  color: var(--color-main-medium);
  padding-bottom: 10px;
}

.Tag_Content {
  word-wrap: normal;
  white-space: pre-wrap;
}

.IonIcon {
  --ionicon-stroke-width: 12px;
  font-size: 20px;
  color: var(--color-main-medium);
}
