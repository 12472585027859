@import '../../../Styles/tokens';
@import '../../../Styles/theme_customer.scss';

.section {
  padding-top: 120px;
  padding-bottom: 120px;
  background: $theme-first-background;
  scroll-margin-top: $scroll-margin-desktop;
  @media screen and (max-width: $mobile-max-width) {
    padding: 50px 0px 50px 0;
    scroll-margin-top: $scroll-margin-mobile;
  }
  .container {
    max-width: $m1470;
    padding: $m1470p;
    margin: auto;
  }
  h3 {
    font-size: $fbig;
    font-family: 'Montserrat';
    color: $cwhite;
    max-width: 700px;
    line-height: 100%;
    margin-bottom: 85px;
    font-weight: 400;
    opacity: 0;
    transition: 0.8s;
    transform: translateX(-100px);
    @media screen and (max-width: $mobile-max-width) {
      font-size: 36px;
      margin-bottom: 50px;
      max-width: 390px;
    }
    span {
      font-weight: 700;
      color: $cblue;
    }
  }
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 88px;
  @media screen and (max-width: $mobile-max-width) {
    gap: 48px;
  }
  .benefit {
    border-top: 1px solid rgba($color: #efefef, $alpha: 0.2);
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    opacity: 0;
    transition: 1.3s;
    transform: translateY(100px);
    @media screen and (max-width: $mobile-max-width) {
      flex-direction: column;
      gap: 25px;
    }
    .benefit__title {
      font-family: 'Montserrat';
      font-size: $fbig;
      opacity: 1;
      color: $cwhite !important;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      @media screen and (max-width: $mobile-max-width) {
        font-size: 32px;
      }
      @media screen and (max-width: 320px) {
        font-size: 36px;
      }
    }
    .benefit__body {
      padding-top: 15px;
      font-family: 'Open Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: #efefef;
      width: 540px;
      @media screen and (max-width: $mobile-max-width) {
        font-size: 18px;
        width: 100%;
      }
      @media screen and (max-width: 320px) {
        font-size: 16px;
      }
      span {
        color: $cblue;
      }

      color: #929292;
    }
  }
}
