.GridItem {
  padding: 5px;
}

.GridDropZone {
  z-index: var(--z-index-B);
}

.Thumbnail {
  width: 100%;
  height: 100%;

  border-radius: 10px;
  border-color: var(--color-main-medium);
  border-style: solid;
  border-width: thin;

  cursor: pointer;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }

    50% {
      -webkit-transform: rotate(2deg);
      transform: rotate(2deg);
    }

    100% {
      -webkit-transform: rotate(-2deg);
      transform: rotate(-2deg);
    }
  }

  &.Thumbnail__MovingAnimation {
    animation: spin 0.3s linear infinite;
    cursor: grab;
  }

  &.Thumbnail__NoAnimation {
    animation: none;
    cursor: pointer;
  }
}

.ButtonWrapper__delete {
  position: absolute;
  right: -5px;
  top: -7px;
  width: 30px;
  height: 30px;
  color: white;
  z-index: 999;

  ion-icon {
    font-size: 32px;
    background: var(--ion-background-color);
  }
}

.IonIcon__add_button {
  --ionicon-stroke-width: 32px;
  font-size: 32px;
  color: var(--color-main-medium);
}

.IonIcon__reorder_button {
  --ionicon-stroke-width: 24px;
  font-size: 21px;
  color: var(--color-main-medium);
}

.Button {
  --padding-start: 0;
  --padding-end: 0;
  margin-inline: 0;
  padding-left: 5px;
  padding-right: 5px;
  height: 32px;

  &.Button__border {
    --border-color: var(--color-main-medium);
    --border-radius: 10px;
    --border-style: dotted;
    --border-width: thin;
  }

  &.Button__forward_plan {
    z-index: var(--z-index-A) !important;
  }
}

.Label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.Text_Reorder {
  word-break: normal;
  white-space: normal;
  font-size: 12px;
}
