.SearchBlock__td {
  padding-bottom: 10px;
  background: var(--ion-background-color);

  &.SearchBlock__td__no_padding {
    padding-bottom: 0px;
  }
}

.SearchBlock__container {
  border-radius: 10px;
  margin: 5px 10px 0px 10px;
  background: var(--color-search-block);
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.1);

  &.SearchBlock__container__no_box_shadow {
    box-shadow: none;
  }
}

.IonChip {
  white-space: nowrap;

  p {
    font-size: 18px;
    user-select: none;
  }
}

.IonIcon__collapse {
  color: var(--color-main-high);
  font-size: 24px;
  --ionicon-stroke-width: 32px;
}

.IonIcon__selected_chip {
  color: var(--ion-color-secondary);
  font-size: 12px;
}

.Category__wrapper {
  border-radius: 20px;
  padding-bottom: 3px;
  cursor: pointer;

  &.Category__wrapper__desktop {
    :hover {
      transform: scale(0.95);
    }
  }

  &.Category__wrapper__highlited {
    background-color: var(--ion-color-secondary);
  }
}

.Image__category {
  margin-top: 5px;
  border-radius: 20px;
  display: block;
  width: 100%;
  height: 65%;
}

.ButtonMicroWrapper {
  float: right;
  height: 30px;
  width: 40px;
}

.Textfit__container {
  margin-top: 3px;
  pointer-events: none;
  transform: translateY(-50%);
}

.Textfit {
  color: white;
  font-weight: 600;
  user-select: none;
  text-align: center;
  white-space: break-space;
  word-break: normal;
}
