@import '../../../Styles/tokens';

.SectionAchivements {
  background: $theme-second-background;
  padding: 70px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: $scroll-margin-desktop;

  @media screen and (max-width: 1350px) {
    padding: 40px 5px;
    scroll-margin-top: $scroll-margin-mobile;
  }

  h3 {
    color: $cblack;
    font-size: $fbig;
    font-family: 'Montserrat';
    font-weight: 400;
    text-align: center;
    transition: 0.8s;
    opacity: 0;
    transform: translateY(100px);
    @media screen and (max-width: 1350px) {
      font-size: 32px;
    }
    @media screen and (max-width: 320px) {
      font-size: 28px;
    }
    span {
      color: $cblue;
      font-weight: 700;
    }
  }
}

.Achivements {
  margin: 64px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 1350px) {
    gap: 15px;
    justify-content: center;
    margin: 25px 0;
  }
  @media screen and (max-width: 720px) {
    gap: 8px;
    display: grid;
    grid-template-columns: auto auto;
  }

  .Achivement {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 75px 20px 75px 20px;
    border-radius: 8px;
    width: 318px;
    height: 314px;
    background: #fff;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
    transition: 1s;
    opacity: 0;
    transform: translateY(100px);
    @media screen and (max-width: 1350px) {
      justify-content: center;
      padding: 30px 5px 30px 5px;
      width: 20vw;
      height: 20vw;
    }
    @media screen and (max-width: 960px) {
      justify-content: center;
      padding: 30px 5px 30px 5px;
      width: 160px;
      height: 160px;
    }
    @media screen and (max-width: 320px) {
      padding: 30px 5px 30px 5px;
      width: 140px;
      height: 140px;
    }
    h5 {
      font-size: 88px;
      font-family: 'Montserrat';
      font-weight: 700;
      color: $cblue;
      line-height: 100%;
      @media screen and (max-width: 1350px) {
        font-size: 65px;
        line-height: 130%;
      }
      @media screen and (max-width: 1150px) {
        font-size: 40px;
        line-height: 130%;
      }
    }
    .Achivement__title {
      margin-bottom: 25px;
      color: $cblue;
      font-family: 'Open Sans';
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      @media screen and (max-width: 1350px) {
        font-size: 18px;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 960px) {
        font-size: 14px;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 320px) {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .Achivement__body {
      color: #646464;
      font-family: 'Open Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      @media screen and (max-width: 1350px) {
        font-size: 14px;
        text-align: center;
      }
      @media screen and (max-width: 960px) {
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
