.Social_Providers__container {
  margin-bottom: 10px;
}

.Google__img {
  margin-top: 4px;
  font-size: 40px;
}

.Apple__img {
  font-size: 44px;
}

.ButtonMicroWrapper {
  height: 50px;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}
