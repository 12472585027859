.radioButton {
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-grow: 1;
  font-weight: 600;
  font-size: 16px;
  gap: 8px;
  justify-content: center;
  padding: 12px;
  cursor: pointer;

  &.radioButton__normal {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.6);
  }

  &.radioButton__normal_active {
    border: 2px solid #787df2;
    color: #787df2;
    // border: 2px solid white;
    // color: white;
  }

  &.radioButton__custom {
    border: 1px solid #cecece;
    color: #686868;
  }

  &.radioButton__custom_active {
    border: 2px solid #696ff1;
    color: #696ff1;
  }
}
