.IonInput {
  --padding-bottom: 7px;
  --padding-top: 7px;

  color: var(--color-main);

  border: var(--color-main-low);
  border-width: thin;
  border-style: solid;
  border-radius: 10px;

  &.IonInput_active {
    border: var(--color-main-high);
    border-width: thin;
    border-style: solid;
    border-radius: 10px;
  }
}
