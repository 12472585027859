.InputYAnchor {
  --padding-top: 0px;
  --padding-bottom: 0px;

  opacity: 0;
  --ion-color-primary: red;

  width: 10px;

  height: 0 !important;
  min-height: 0 !important;
  > ion-input {
    height: 0 !important;
    min-height: 0 !important;
  }
}
