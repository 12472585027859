.Title {
  margin-top: 10px;
  margin-bottom: 5px;
  color: var(--color-main);
  line-height: 1.2;
  font-weight: 700;
  font-size: 18px;
}

.Subtitle {
  color: var(--color-main-medium);
  font-size: 16px;
  line-height: 1;
}

.ImagePrev {
  border-radius: 5px;
  aspect-ratio: 1;
  width: 100%;
  object-fit: cover;

  &.ImagePrev__active {
    border: solid 1px white;
  }
}

.MediaContainer {
  background-color: var(--color-card-auth);
  box-shadow: 0px 0px 10px 5px var(--color-card-auth);
  margin: 10px;
  margin-top: 15px;
  border-radius: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.Play__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-main-high);
  z-index: inherit;
  --ionicon-stroke-width: 24px;
  height: 70%;
  width: 70%;
}
