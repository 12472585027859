@import '../../../styles/tokens';

.IonButton {
  height: fit-content;
  width: 60%;

  div {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      white-space: normal;
      word-break: normal;
    }
  }
}
