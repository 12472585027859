.IonButton {
  --padding-start: 0;
  --padding-end: 0;
  margin-inline: 0;
  padding-left: 0px;
  padding-right: 0px;

  &.IonButton__no_ripple {
    --ripple-color: transparent;
  }

  &.IonButton__resize_animation {
    // Disable ripple effect and focus effect
    --ripple-color: transparent;
    --ion-color-contrast: none;
    --ion-color-primary: transparent;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      transform: scale(1.1);
    }
  }

  &.IonButton__softDisabled {
    &::part(native) {
      cursor: default !important;
    }

    &:active {
      pointer-events: none;
    }
  }
}
