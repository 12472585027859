.ButtonMicroWrapper {
  width: fit-content;
  --background: var(--color-search-block);
  --padding-start: 10px !important;
  --padding-end: 5px !important;

  &.ButtonMicroWrapper__add_border {
    border-radius: 15px;
    border: solid 1px var(--ion-color-secondary-tint);
  }

  &.ButtonMicroWrapper__zIndex_back {
    z-index: -1;
  }

  &.ButtonMicroWrapper__right {
    float: right;
  }

  p {
    font-size: 12px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
      sans-serif;
    padding-right: 0px;
    white-space: nowrap;
  }
}

.IonIcon {
  --ionicon-stroke-width: 24px;
  font-size: 24px;
  color: var(--color-main-medium);
}
