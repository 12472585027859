// IonChip
.ion-color {
  --ion-color-base-rgb: none !important;
  --ion-color-shade: var(--color-main-medium) !important;
}

.ion-color.custom-ion-chip {
  background: rgba(90, 90, 90, 1);
}

.ion-color.custom-ion-chip:not(.chip-outline) {
  background: rgba(70, 70, 70, 1);
}
