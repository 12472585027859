.NoBorders {
  border: none;
}

.IonModal {
  --width: 100%;
  --height: 100%;
  --backdrop-opacity: 0;

  &.IonModal__desktop {
    border-radius: 20;
    --width: 85%;
    --height: 95%;
    --backdrop-opacity: 0.8;
  }
}

.Move__button {
  width: 80%;
  height: 80%;
}

.Close__button {
  color: var(--color-main-high);
  width: 48px;
  height: 48px;
  margin-left: -10px;
}

.Zoom__button {
  color: var(--color-main-high);
  width: 32px;
  height: 32px;
}

.Swiper {
  width: 100%;
  height: 100%;
}

html {
  ::-webkit-scrollbar-corner {
    background: none;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--color-main-medium);
  }
}
