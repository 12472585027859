@import '../../../styles/tokens';

.ButtonMicroWrapper {
  --padding-end: 0px;
  --padding-start: 0px;

  p {
    margin: 5px;
    opacity: $transparency-middle;
    font-size: 14px;
  }
}
