.IonCard__info {
  margin-bottom: 15px;

  p {
    font-size: 14px;
    color: var(--color-main-high);
  }
}

.Container {
  margin-top: 50px;
  margin-bottom: 20px;
}

.Text__Header {
  font-size: 18px;
}

.Text__Optional {
  color: var(--color-main-low);
  font-size: 18px;
}
