@import "../../../styles/tokens";

.InputWrapper__container {
  margin-bottom: 10px;
  width: 100%;

  input:focus {
    outline: none;
  }
}

.TextLabel__container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    font-size: $font-size-micro;
  }
}

.TextLabel__container_error {
  width: 100%;
  padding-right: 50px;

  p {
    color: red;
  }
}

.TextLabel__container_counter {
  position: absolute;
  padding-right: 15px;
  width: auto;
}
