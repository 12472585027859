.Subject__container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.IonPopover {
  --backdrop-opacity: 0.8;
  --width: auto;

  &::part(content) {
    max-height: calc(100vh - 220px);
    background-color: var(--color-card-auth);
    border: solid 1px rgba(255, 255, 255, 0.2);
  }
}

.Subject_Choice__hr {
  margin-top: 5px;
  height: 1px;
  width: 100%;
  opacity: 0.25;
  background: white;
}
