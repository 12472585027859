// Duplicate: ConfirmationModal.module.scss
.IonModal {
  --height: auto;
  --backdrop-opacity: 0.9;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 10px;
}

.Text__Header {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.Text__Content {
  text-align: center;
  white-space: pre-wrap;
}

.Close__button {
  color: var(--color-main-high);
  width: 48px;
  height: 48px;
  margin-left: -10px;
}
// ^^

.IonIcon__copy {
  --ionicon-stroke-width: 24px;
  font-size: 24px;
  color: var(--color-main-medium);
}

.Text__dotted_border {
  width: fit-content;
  padding: 0 5px;
  border-radius: 10px;
  border: dotted 1px var(--color-main-medium);
}

.WhatsApp_button {
  width: 100%;
  height: 46px;
}
