.InputCurrency {
  height: 40px;
  padding: 7px;
  background: none;
  width: 100%;

  color: var(--color-main);

  border: var(--color-main-low);
  border-width: thin;
  border-style: solid;
  border-radius: 10px;

  &.InputCurrency_active {
    border: var(--color-main-high);
    border-width: thin;
    border-style: solid;
    border-radius: 10px;
  }
}
