$internal-round: 50px;
$border-color: var(--ion-background-color);
$border-width: 5px;
$color-tab: #2f3133;

#fakeTabBackgroundContainer {
  width: calc(100% + 4px);
  height: 100%;
  border-top: solid $border-width $border-color;
  margin-top: 10px;

  // THIS:
  background: $color-tab;
  // OR THIS:
  // background: $border-color;
  // #firstPieceOfFakeBackground {
  //   background: $color-tab;
  //   width: 100%;
  //   height: 100%;
  //   border-top-right-radius: 25px;
  // }

  // #secondPieceOfFakeBackground {
  //   background: $color-tab;
  //   width: 64px;
  //   margin-top: 32px;
  //   height: 100%;
  //   flex-shrink: 0;
  // }

  // #thirdPieceOfFakeBackground {
  //   background: $color-tab;
  //   width: 100%;
  //   height: 100%;
  //   border-top-left-radius: 25px;
  // }
}

#externalRound {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  border-radius: 100%;
  width: $internal-round + 2 * $border-width;
  height: $internal-round + 2 * $border-width;
  position: absolute;
  background: $border-color;
}

#internalRound {
  background: $color-tab;
  width: $internal-round;
  height: $internal-round;
  border-radius: 100%;
}

#ionTabBar {
  position: absolute;
  border: none !important;
  bottom: 0;
  width: 100%;

  ion-tab-button {
    max-width: unset;
  }

  ion-tab-button:not(.centralTab) {
    &::part(native) {
      padding-top: 10px;
      justify-content: unset;
      margin-top: 10px;
      background: $color-tab;
      border-top: solid $border-width $border-color;
    }
  }
}

#activeTabEventmaker {
  font-size: 28px;
  margin-top: 8px;
}

#inactiveTabEventmaker {
  font-size: 24px;
  margin-top: 15px;
}
