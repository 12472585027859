.Centralizer {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  &.Centralizer__enable_vertical_centralization {
    justify-content: center;
  }

  &.Centralizer__enable_page_padding {
    padding: 5px;
  }
}
