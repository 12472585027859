.IonModal {
  --width: 100%;
  --height: 100%;
  --backdrop-opacity: 0.9;

  &.IonModal__desktop {
    --height: 85%;
    --width: 65%;
    --max-width: 400px;
  }

  &.IonModal__mobile {
    > div {
      // for breakpoint 0.8
      height: 80% !important;
    }
  }
}

.Text__header {
  font-size: 18px;
}

.Close__button {
  color: var(--color-main-high);
  width: 48px;
  height: 48px;
  margin-left: -10px;
}

.IonToolbar {
  border: none;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  height: 50px;
}

.IonSearchbar {
  color: var(--color-main);
  padding: 0px;
  margin: 0px;
  height: 50px !important;
  min-height: unset;
  .searchbar-search-icon {
    inset-inline-start: 20px !important;
  }

  input {
    padding-inline-start: 45px !important;
  }

  ion-icon {
    display: block;
    color: var(--color-main-high);
    --ionicon-stroke-width: 40px;
    font-size: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Button__Search {
  padding-left: 15px;
  margin: 0 !important;
  width: 100% !important;
}
