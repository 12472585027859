// Without this animation "refetch" icon do fast renders
// when it is not necessary (initial undefined)
@keyframes opacityIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.IonIcon__refetch {
  font-size: 40px;
  color: var(--color-main-medium);
  animation: opacityIn 0.3s ease-in 1;
}

.IonSpinner {
  height: 40px;
  color: var(--color-main-medium);
}

.TableVirtuoso {
  table {
    min-width: 100%;
  }

  > div > table > tbody > tr:first-child > td {
    padding-top: 10px;
  }

  > div > table > tbody > tr:last-child > td {
    padding-bottom: 100px;
  }

  td {
    // width bugfix
    min-width: 1px;
    max-width: 1px;
    user-select: none;

    // nested table
    td {
      vertical-align: top;
    }
  }

  // Scrollbar
  overflow-x: hidden; /* Chrome */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.TableVirtuoso::-webkit-scrollbar {
  display: none;
}
