.IonIcon_Placeholder {
  width: 70%;
  height: 70%;
  color: var(--color-main-medium);
}

.IonIcon_Video {
  position: absolute;
  width: 50%;
  height: 50%;
  color: var(--color-main-high);
  z-index: var(--z-index-A);
  --ionicon-stroke-width: 16px;
}

.PreviewImg {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 10px;

  &.PreviewImg__additional_opacity {
    opacity: 0.2;
  }
}

.Centralizer__position {
  position: absolute;
  right: 0;
  top: 0;
}
