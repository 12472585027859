.IonModal {
  --height: auto;
  --backdrop-opacity: 0.8;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 10px;
  --max-width: 90%;

  &::part(content) {
    padding: 20px 10px 5px;
  }
}

.Text__Header {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin: 20px;
}

.Text__Content {
  text-align: center;
  line-height: 1.5;
  white-space: pre-line;
  text-underline-offset: 3px;
}
