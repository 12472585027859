.Spinner {
  --color: white;
}

.Centralizer_unique {
  position: absolute;
  z-index: 777;
  pointer-events: none;
  left: 0;
  right: 0;
}
