@import '../../../Styles/tokens';

.footer {
  padding: 88px 0px;
  background: #161616;
  @media screen and (max-width: $mobile-max-width) {
    padding: 40px 0px;
  }

  .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
    margin: auto;
    max-width: $m1470;
    padding: $m1470p;
    @media screen and (max-width: $mobile-max-width) {
      display: grid;
      grid-template-columns: auto auto;
      gap: 30px;
      padding: 0px 20px;
      justify-content: space-around;
    }
  }
  .count2 {
    grid-column: span 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo {
    margin-bottom: 24px;
    width: 290px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .links {
    display: flex;
    justify-content: center;
    gap: 65px;
    margin-bottom: 24px;
    @media screen and (max-width: $mobile-max-width) {
      gap: 33px;
    }
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    a {
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .download {
    display: flex;
    gap: 16px;
    cursor: pointer;
    img {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0;

    li {
      p {
        display: block;
        font-family: 'Open Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 120%;
        color: #c2c2c2 !important;

        &:hover {
          opacity: 0.8;
        }
        @media screen and (max-width: $mobile-max-width) {
          font-size: 14px;
        }
        @media screen and (max-width: 320px) {
          font-size: 12px;
        }
      }
      cursor: pointer;
    }
    .nav {
      cursor: initial;
      p {
        color: white !important;
        font-weight: 600 !important;
        margin-bottom: 10px;
      }
    }
  }
}

.empty {
  display: none;
  @media screen and (max-width: $mobile-max-width) {
    display: block;
    height: 23px;
  }
}
.nav_desktop {
  @media screen and (max-width: $mobile-max-width) {
    display: none !important;
  }
}

.nav_mobile {
  display: none !important;
  @media screen and (max-width: $mobile-max-width) {
    display: flex !important;
  }
}
