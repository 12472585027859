$border-color: 1px solid rgba(255, 255, 255, 0.5);

.rectangle_small {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 10px;
  border: $border-color;
}

.circle_small {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 30px;
  border: $border-color;
}

.Image_No_Distortion__container {
  overflow: hidden;
  border: $border-color;
  border-style: solid;

  ion-img {
    height: 100%;
  }

  ion-img::part(image) {
    object-fit: cover;
  }
}
