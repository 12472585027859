@import '../../../Styles/tokens';

.section {
  margin: auto;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: $m1470;
  padding: $m1470p;
  padding-top: 120px;
  padding-bottom: 120px;
  @media screen and (max-width: $mobile-max-width) {
    flex-direction: column-reverse;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 0px;
  }
  .left {
    opacity: 0;
    transition: 1.3s;
    transform: translateX(-130px);
    @media screen and (max-width: $mobile-max-width) {
      transform: translateY(50px);
    }
  }
  .img {
    width: 545px;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
    opacity: 0;
    transition: 1.3s;
    transform: translateX(130px);
    @media screen and (max-width: $mobile-max-width) {
      transform: translateY(50px);
      width: 740px;
      height: 230px;
      margin-bottom: 30px;
    }
  }
  .section__title {
    color: #242424;
    font-family: 'Montserrat';
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 36px;
    max-width: 740px;
    @media screen and (max-width: $mobile-max-width) {
      font-size: 28px;
      margin-bottom: 25px;
    }
    @media screen and (max-width: 320px) {
      font-size: 24px;
    }
    span {
      font-weight: 700;
      color: $cblue;
    }
  }
  .section__text {
    margin-bottom: 64px;
    color: #242424;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    max-width: 530px;
    @media screen and (max-width: $mobile-max-width) {
      font-size: 18px;
      margin-bottom: 35px;
    }
    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
  button {
    @media screen and (max-width: $mobile-max-width) {
      width: 100%;
    }
  }
}
