/* fonts default */
$font-family-default: system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Ubuntu',
  'Cantarell', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol', 'Noto Color Emoji';

/* fonts */
$font-family-main: 'Inter', sans-serif;
$font-family-secondary: 'GillSans', sans-serif;

/* font-size */
$font-size-big: 18px;
$font-size-regular: 16px;
$font-size-small: 14px;
$font-size-micro: 12px;

/* font-weight */
$font-weight-bold: 700;
$font-weight-regular: 400;

/* colors */
$color-terms-new: #42a215;
$color-terms-change: #ffee66;
$color-terms-delete: #f01919;

/* opacity-transparency */
$transparency-low: 0.8;
$transparency-middle: 0.6;
$transparency-high: 0.4;

/* breakpoints */
$xs: 300px;
$sm: 400px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
