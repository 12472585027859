.TextLink {
  width: fit-content;
  color: #929292 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2.3px;
  text-decoration-style: dotted;

  &:hover {
    color: var(--color-main-high) !important;
    cursor: pointer;
  }
}
