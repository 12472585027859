.CardItem {
  -webkit-transform: translate3d(0, 0, 0);
  background-color: var(--color-card);
  border-radius: 10px;
  box-shadow: rgba(244, 244, 244, 0.2) 0px 0px 0px -2px,
    rgba(244, 244, 244, 0.14) 0px 1px 2px 0px,
    rgba(244, 244, 244, 0.12) 0px 0px 2px 0px;

  // box-shadow: 0px 0px 10px 0.5px var(--color-main-low);

  // box-shadow: rgba(244, 244, 244, 0.2) 0px 0px 10px -2px,
  //   rgba(244, 244, 244, 0.14) 0px 1px 2px 0px,
  //   rgba(244, 244, 244, 0.12) 0px 0px 2px 0px;

  padding: 5px;
  margin: 10px;
  height: calc(100% - 20px); // 2 * margin

  &.CardItem__hover_animation {
    &:hover {
      transform: scale(1.02);
      cursor: pointer;
    }
  }

  &.CardItem__flex {
    display: flex;
    flex-flow: column;
  }
}
