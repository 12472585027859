@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GillSans';
  src: url('../assets/fonts/GillSans.woff') format('woff');
  font-weight: 200;
  font-style: regular;
}

@font-face {
  font-family: 'Preahvihear';
  src: url('../assets/fonts/Preahvihear.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
