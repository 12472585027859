.IonSelect {
  width: 100%;
  color: var(--color-main);
  --padding-start: 7px;

  .native-wrapper {
    display: none;
  }

  &.IonSelect__border {
    border: var(--color-main-low);
    border-width: thin;
    border-style: solid;
    border-radius: 10px;
  }

  &.Active {
    border: var(--color-main-high);
    border-width: thin;
    border-style: solid;
    border-radius: 10px;
  }

  &::part(label) {
    margin-inline: 0px !important;
  }
}

.Popover_Interface {
  --backdrop-opacity: 0.65;
  --width: auto;

  h3 {
    color: grey;
  }

  ion-item {
    --inner-padding-start: 7px;
    --inner-border-width: 0;
    --padding-start: 0px;
    --color: var(--color-main-high);
    --ion-color-primary: none;
    --ion-color-primary-shade: transparent;
  }

  ion-list {
    margin: 10px !important;
  }
}
