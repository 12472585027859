.SignUp_Form_Fields__container {
  margin-bottom: 10px;
}

.Agreement__text {
  font-size: 12px;
  text-align: center;
  white-space: pre-line;
  line-height: 1.3;
}

.Agreement__button {
  background: none;
}

.Agreement__link {
  color: white;
  text-decoration: underline;
  font-size: 12px;
}
