@import '../../styles/tokens';

.LocationIcon {
  font-size: 22px;
  color: white;

  &.LocationIcon__mini {
    font-size: 16px;
  }

  &.LocationIcon__black {
    color: black;
  }
}
