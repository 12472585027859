@import '../../styles/tokens';

.BuyBlock__container {
  border: none;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0 10px;
  background: var(--color-search-block);
  height: 50px;

  &.BuyBlock__container__ProductDetailPage {
    margin: 10px auto;
    width: calc(100% - 10px);
    max-width: 600px;
  }
}

.IonIcon {
  --ionicon-stroke-width: 24px;
  font-size: 32px;
  color: var(--color-main);
}

.Text_Price {
  white-space: nowrap;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  color: var(--color-main);
  overflow: hidden;
}

.IonIcon__wrapper__message {
  margin-left: 10px;
}

.Price_Wrapper {
  width: 100%;
  margin-left: -5px;
  align-items: center;
  justify-content: center;

  .Price_Wrapper__preview {
    width: fit-content;
    max-width: calc(100% - 60px);
  }
}

.Text__payment {
  font-family: $font-family-secondary;
  font-size: 14;
  text-align: center;
  white-space: nowrap;
}
