@import '../../styles/tokens';

.List__container {
  width: 50%;
  padding-top: 0px;
  padding-bottom: 0px;

  ion-reorder {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 45px;
    height: fit-content;

    p {
      white-space: normal;
      word-break: break-word;
      text-align: start;
    }
  }
}

.Item__container {
  margin-right: 5px;
  margin-bottom: 5px;

  &.Item__left {
    padding-left: 5px;

    border: var(--color-main-low);
    border-width: thin;
    border-style: dotted;
    border-radius: 10px;

    ion-button {
      height: fit-content;
      width: 40px;
    }

    ion-button::part(native) {
      padding-inline-start: 0px;
      padding-inline-end: 0px;
    }
  }

  &.Item__right {
    opacity: $transparency-high;

    ion-button {
      height: fit-content;
      --padding-end: 10px;
      --padding-start: 10px;
    }

    ion-button::part(native) {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }
}

.Vertical_Line {
  border: solid 0.1px var(--color-main-low);
}

.IonIcon {
  font-size: 28px;
  margin-right: 5px;
  height: 40px;
}
