.Swiper {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.IonIcon__navigation {
  color: var(--color-navigation-elements);
  font-size: 32px;
}

@mixin NavigationButton {
  position: absolute;
  z-index: var(--z-index-A);
  top: calc(50% - 8px);
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  --border-radius: 25px !important;
  --background-hover-opacity: 0.4;
}

.SwiperButton__next {
  @include NavigationButton;
  right: 0;
}

.SwiperButton__prev {
  @include NavigationButton;
  left: 0;
}

.Play__button {
  position: absolute;
  width: 50px;
  height: 50px;
  color: var(--color-main-high);
  z-index: inherit;
  --ionicon-stroke-width: 24px;
}
