// Open Sans
@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/landing/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/landing/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/landing/OpenSans-SemiBold.woff')
    format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/landing/OpenSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../../assets/fonts/landing/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

// Montserrat
@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/landing/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/landing/Montserrat-Medium.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/landing/Montserrat-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../../../assets/fonts/landing/Montserrat-SemiBold.woff')
    format('woff');
  font-weight: 600;
  font-style: normal;
}

// SuezOne
@font-face {
  font-family: 'SuezOne';
  src: url('../../../assets/fonts/landing/SuezOne-Regular.ttf') format('woff');
  font-weight: 400;
  font-style: normal;
}
