@import '../../../styles/tokens';
@import '../../../styles/fonts';

.Button_Horizontal {
  width: 100%;
  height: auto;
  margin: 0px;

  border-radius: 10px;

  // Soft disabled
  opacity: 1 !important;

  --padding-start: 0px;
  --padding-end: 0px;
  --border-color: none;
  --border-width: 0px !important;

  // Disable default ripple
  --ripple-color: transparent;
  --ion-color-contrast: none;
  --ion-color-primary: transparent;

  &.Button_Horizontal__desktop {
    &:hover {
      background: var(--color-main-hover);
    }
  }

  &.Button_Horizontal__mobile {
    &:active {
      background: var(--color-main-hover);
    }
  }
}

.Button_Content__container {
  height: auto;
  width: 100%;

  hr {
    // margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: var(--color-main-medium);
    opacity: 0.5;
    width: 100%;
  }

  p {
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    text-align: left;
    // padding-left: 10px;
    // padding-right: 10px;
    margin: 0 5px;
  }

  > p {
    margin-top: 7px;
    margin-bottom: 7px;
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
