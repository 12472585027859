@import '../../../Styles/tokens';

.section {
  background: #f8f8f8;
  padding: 85px 15px 85px 15px;
  scroll-margin-top: $scroll-margin-desktop;
  @media screen and (max-width: $mobile-max-width) {
    padding: 30px 15px;
    // Image height compensation
    scroll-margin-top: calc($scroll-margin-mobile - 260px);
  }
}

.container {
  margin: auto;
  max-width: $m1470;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  img {
    width: 545px;
    height: 545px;
    object-fit: cover;
    border-radius: 8px;
    transition: 0.8s;
    opacity: 0;
    transform: translateX(100px);
    @media screen and (max-width: $mobile-max-width) {
      margin-top: 0;
      width: 100%;
      height: 225px;
      transform: translateX(60px);
      margin-bottom: 36px;
    }
  }
  @media screen and (max-width: $mobile-max-width) {
    flex-direction: column-reverse;
    gap: 0px;
    max-width: 740px;
  }
}

// Animation?
// .form {
//   transition: 0.8s;
//   opacity: 0;
//   transform: translateY(100px);
// }

.inapp_block {
  margin-top: 15px;
  padding: 5px;

  .sectionHeader {
    color: #696ff1;
    font-family: 'Montserrat';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }

  .sectionSubheader {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }
}

.left {
  width: 650px;
  @media screen and (max-width: $mobile-max-width) {
    width: 100%;
  }

  .sectionHeader {
    color: #696ff1;
    font-family: 'Montserrat';
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
    transition: 0.8s;
    opacity: 0;
    transform: translateX(-100px);
    @media screen and (max-width: $mobile-max-width) {
      font-size: 28px;
      margin-bottom: 20px;
      transform: translateX(-70px);
    }
    @media screen and (max-width: 320px) {
      font-size: 24px;
    }
  }

  .sectionSubheader {
    color: #1e1e1e;
    font-family: 'Open Sans';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 30px;
    transition: 0.8s;
    opacity: 0;
    transform: translateX(-100px);
    @media screen and (max-width: $mobile-max-width) {
      transform: translateX(-70px);
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
}

.Chips_container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 20px;
  padding: 0 4px;

  @media screen and (max-width: 650px) {
    gap: 8px;
  }
}

.IonInputSelect__custom {
  max-height: 72px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #cecece !important;
  color: #4f4f4f;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 230px;
  padding: 5px;
}

.IonInputLabel__custom {
  font-weight: 600;
  color: black;

  &.IonInputLabel__custom__margin {
    margin-top: 5px;
  }
}

.IonInput__custom {
  border-radius: 8px;
  border: 1px solid #cecece !important;
  background: #fff !important;
  color: #4f4f4f !important;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px !important;
  &.IonInput__custom__active {
    border: 2px solid #979797 !important;
  }
}

.IonTextarea__custom {
  border-radius: 8px;
  border: 1px solid #cecece !important;
  background: #fff !important;
  color: #4f4f4f !important;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px;
  width: 100%;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    padding: 3px !important;
    padding-top: 0 !important;
  }
  &.IonTextarea__custom__active {
    border: 2px solid #979797 !important;
  }
}

.text {
  color: #686868;
  font-size: 24px;
  line-height: 28.8px;
  margin-top: 64px;
  margin-bottom: 36px;
  max-width: 500px;
  opacity: 1;
  transform: none;
}

.whatsAppButton {
  align-items: center;
  background: #0ec254;
  display: flex;
  gap: 8px;
  justify-content: center;

  // margin-top: 25px;

  cursor: pointer;
  width: 100%;
  padding: 16px 30px 16px 36px;
  color: #fff;

  font-size: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  border-radius: 32px;

  height: 56px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    width: 100%;
  }
}
