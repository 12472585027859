@import './fonts';
@import './tokens';

.show-animation {
  opacity: 1 !important;
  transform: translate(0px, 0px) !important;
}

.bodyBackground {
  background-color: white;
}
